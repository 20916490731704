//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin: $indent__base !default;
$checkout-wrapper__columns: 16 !default;

$checkout-step-title__border: $border-width__base solid $border-color__base !default;
$checkout-step-title__font-size: 26px !default;
$checkout-step-title__font-weight: $font-weight__light !default;
$checkout-step-title__padding: $indent__s !default;

$checkout-step-title-mobile__font-size: 18px !default;

.page-layout-checkout {
    .page-header {
        background: $brand__primary__color;
        margin-bottom: $indent__m;
    }

    .header-middle-bar__logo {
        justify-content: space-between;
        margin: 0 auto 20px;
        width: 100%;
    }

    .header-middle-bar__checkout {
        display: flex;
        padding-top: 15px;
        padding-bottom: 15px;
        justify-content: space-between;
        align-items: center;

        & > a {
            img {
                margin-bottom: -15px;
            }
        }
    }

    .customer-service-header {
        display: flex;
        flex-direction: column;
        color: $color-white;

        a {
            color: $theme-color-yellow3;
            font-size: 13px;
            font-weight: 500;

            @include lib-icon-font(
                $_icon-font-content: $icon-phone-alt-solid,
                $_icon-font-size: 13px,
                $_icon-font-margin: 0 5px 0 0
            );
        }
    }

    .action-auth-toggle {
        span {
            color: $color-white;
        }
    }
}

.checkout-index-index {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .field {
        &._required {
            > .label::after {
                content: '*';
                color: $color-red10;
                font-size: 13px;
                margin: 0 0 0 5px;
            }
        }

        &.street {
            > .label {
                display: none;
            }

            > .control {
                display: flex;
            }

            .field {
                display: inline-block;
                width: calc(50% - 3px);

                &.additional {
                    margin-left: 10px;
                    margin-top: 0;
                    width: calc(25% - 10px);
                }
            }
        }

        &[name="shippingAddress.firstname"],
        &[name="shippingAddress.postcode"] {
            display: inline-block;
            width: calc(50% - 7px);
            margin-right: 7px;
        }

        &[name="shippingAddress.lastname"],
        &[name="shippingAddress.city"] {
            display: inline-block;
            width: calc(50% - 7px);
            margin-left: 7px;
        }
    }
}

.checkout-onepage-success,
.checkout_onepage_success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;
    }

    .checkout-onepage-success,
    .checkout_onepage_success {
        .print {
            @include lib-button();
            @include lib-link-as-button();
            float: right;
        }
    }
}

@include max-screen($screen__s) {
    .page-layout-checkout {
        .header-middle-bar__checkout {
            justify-content: center;

            .customer-service-header {
                display: none;
            }
        }
    }
}

@include max-screen($screen__l) {
    .page-layout-checkout {
        .header-middle-bar__logo {
            margin-top: 0;
            padding-top: 15px;
        }
    }
}
