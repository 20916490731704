//
//  Product Lists
//  _____________________________________________

.product-overview.grid {
    .product-item {
        margin-bottom: $indent__l;
    }
}

.product-item {
    .tocart {
        &:after {
            margin: -8px 0 0 2px;
        }
    }
}

.products {
    margin-top: $indent__l;
    margin-bottom: $indent__l;

    .product-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
    }

    &.products-related,
    &.products-upsell,
    &.products-crosssell {
        .product-items {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &.swiper-wrapper {
                flex-wrap: nowrap;
            }
        }
    }

    .price-box {
        .old-price {
            .price {
                font-size: 12px;
                color: $theme-color-dark-gray1;
            }
        }

        .special-price {
            .price {
                color: #c14b20;
            }
        }
    }
}

.stock {
    display: flex;
    align-items: center;

    &.available {
        @include lib-icon-font(
            $_icon-font-content: $icon-success,
            $_icon-font-size: 16px
        );
        color: $brand__primary__color;
    }

    &.unavailable {
        @include lib-icon-font(
            $_icon-font-content: $icon-error,
            $_icon-font-size: 16px
        );
        color: $error__color;
    }
}

.products-list {
    .product-wrapper {
        padding: 5px 0 10px 0;
        flex-direction: column;
    }

    .product-item .price-final_price.price-box {
        flex-direction: column;
        align-items: end;
    }

    .product {
        .product-item-name {
            height: auto;
            margin: 0;
        }
    }

    .product-item-description {
        display: none;
    }
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        .products-grid & {
            display: inline-block;
        }

        .products-grid &-name {
            @include text-truncate(15px);
            margin-top: 7px;

            a {
                font-weight: $font-weight__semibold;
                word-wrap: break-word;
                hyphens: none;
            }
        }

        .attribute-title  {
            font-size: 13px;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            word-wrap: break-word;
            hyphens: auto;
        }

        &-info {
            max-width: 100%;
        }

        &-actions {
            display: none;

            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto;

                    &::before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-final_price.price-box {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row-reverse;
            align-items: center;

            .price {
                white-space: nowrap;
            }

            .price-label {
                display: none; // Hide the price label
            }
        }

        .special-price,
        .minimal-price {
            margin: 0;

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            display: none;
        }

        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .tocompare {
            @include lib-icon-font-symbol($icon-compare-full);
        }

        .tocart {
            @extend .btn--rounded;
            @include lib-icon-font(
                    $icon-expand,
                $_icon-font-size: 9px,
                $_icon-font-text-hide: true,
                $_icon-font-position: after
            );
            @include lib-icon-font(
                    $icon-cart,
                $_icon-font-size: 17px
            );
            white-space: nowrap;
        }
    }

    .attribute-title {
        color: $theme-color-dark-gray1;
    }

    .traffic-light {
        display: flex;
    }
}

.price-container {
    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .weee {
        &::before {
            content: '(' attr(data-label) ': ';
        }

        &::after {
            content: ')';
        }

        + .price-excluding-tax {
            &::before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .photo-wrapper {
        position: relative;
        margin-right: 20px;
    }

    .product {
        &-item {
            width: 100%;

            &.item {
                padding-left: 0;
            }

            &-name {
                margin-top: 0;
            }

            &-info {
                display: flex;
                padding: 20px 0;
                border-bottom: 1px solid $border-color__base;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

@include max-screen($screen__l) {
    .product {
        &-item {
            .products-grid &-name {
                @include text-truncate(14px);
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .products-grid {
        .products.product-items {
            .swatch-attribute {
                display: none;
            }
        }
    }

    .products {
        .product-wrapper {
            flex-direction: column;
            margin-top: 10px;

            .actions-primary {
                & > form {
                    margin-top: 10px;
                }
            }
        }

        .price-final_price.price-box {
            justify-content: flex-end;
        }
    }

    .list {
        .product-item-info {
            flex-direction: column;
        }
    }

    .products {
        .product-wrapper {
            height: auto;
            margin-top: 10px;
        }

        .stock {
            margin-top: 10px;
        }
    }
}

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }

    .product-reviews-summary,
    .product-item-details [class^=swatch] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product {
        &-item {
            .products-list &-name {
                font-size: 17px;
            }

            .product-overview &-name {
                @include text-truncate(15px);
            }

            &-actions {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .actions-primary + .actions-secondary {
                    margin: 10px 0;
                    padding-left: 10px;
                    white-space: nowrap;

                    & > * {
                        white-space: normal;
                    }

                    & > .action:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .products {
        .product-wrapper {
            align-items: center;

            .price-box {
                .price {
                    margin-bottom: 0;
                }
            }
        }
    }
}
