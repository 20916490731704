$product-grid-items-per-row-layout-default: 2 !default;

$product-grid-items-per-row-layout-1-screen-s: 3 !default;
$product-grid-items-per-row-layout-1-screen-m: 4 !default;
$product-grid-items-per-row-layout-1-screen-l: 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s: 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m: 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l: '' !default;

$product-grid-items-per-row-layout-2-right-screen-s: 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m: 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l: '' !default;

$product-grid-items-per-row-layout-3-screen-s: 3 !default;
$product-grid-items-per-row-layout-3-screen-m: '' !default;
$product-grid-items-per-row-layout-3-screen-l: '' !default;

$product-grid-items-padding: 0 $indent__base $indent__base !default;
$product-grid-items-margin: 0 0 $indent__s !default;

$product-name-text-decoration: none !default;
$product-name-text-decoration-hover: $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size: 14px !default;
$product-h1-margin-bottom-desktop: $indent__base !default;

@import 'module/listings';
@import 'module/columns';
@import 'module/toolbar';
@import 'module/gallery';
@import 'module/prices';
@import 'module/category';

//
//  Category view
//  ---------------------------------------------

.catalog-product-view {
    .product-add-form .fieldset > .field {
        margin: 0 0 10px;
    }
}

.subcategory-listing.page-layout-2columns-left {
    .page-wrapper {
        .sidebar-container {
            display: none;
        }

        .column.main {
            flex: 1;
            max-width: 100%;
        }

        .category-grid {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 20px;

            &__item {
                width: auto;
                margin-bottom: 0;
                border-radius: 5px;
                border: 1px solid #dfdfdf;

                .category-grid__image-anchor {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba($theme-color-light-blue3, 0.5);
                    padding: 20px 40px;
                }
            }

            &__details {
                padding: 12px 10px;

                a {
                    color: $color-blue4;
                    font-size: 14px;
                    display: block;
                    line-height: 18px;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .subcategory-listing.page-layout-2columns-left {
        .page-wrapper {
            .category-grid {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 20px;
            }
        }
    }
}


@include max-screen($screen__s) {
    .subcategory-listing.page-layout-2columns-left {
        .page-wrapper {
            .category-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
            }
        }
    }
}

.category-view {
    .page-title-wrapper {
        h1 {
            font-size: 30px;
        }
    }
}

.go-back {
    @include lib-icon-font(
        $_icon-font-content: $icon-prev,
        $_icon-font-size: 10px,
        $_icon-font-color: #8f8f93,
        $_icon-font-margin: 0 10px 0 0
    );
    @include lib-link(
        $_link-color: #8f8f93,
        $_link-color-visited: #8f8f93,
        $_link-color-hover: #8f8f93,
        $_link-color-active: #8f8f93
    );
}


.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &::before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &::before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-image {
    margin-bottom: $indent__s;
}

//
//  Product images general container
//  ---------------------------------------------

.product.media {
    position: relative;
}

.product-image-container {
    display: inline-block;
    max-width: 100%;
}

.fotorama__stage__shaft,
.fotorama__thumb,
.product-image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: '';
        pointer-events: none;
        background: $color-black;
        width: 100%;
        height: 100%;
        z-index: 10;
        position: absolute;
        opacity: 0.03;
        left: 0;
        top: 0;
    }
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product-item-info {
    .price-box {
        .old-price {
            order: 2;
        }

        .old-price + .normal-price {
            .price-container {
                .price {
                    color: #c14b20;
                }
            }
        }
    }
}

.product-info-columns {
    .product-item .price-final_price.price-box {
        flex-direction: row;
    }
}

.product-info-detail-wrapper {
    .product-labels {
        align-items: flex-end;
    }
}

.product.info.detailed {
    clear: both;

    .product.data.items {
        margin-bottom: 0;
    }

    .additional-attributes {
        @include lib-table-resize(
            $_th-padding-left: 0,
            $_th-padding-right: $indent__l,
            $_th-padding-bottom: $indent__s,
            $_td-padding-bottom: $indent__s
        );
    }
}

.product-info-main {
    display: flex;
    flex-direction: column;

    .product-social-links {
        order: 5;
    }

    .product-attachments-container {
        order: 6;
    }
}


a.login-for-price__link {
    @include lib-link-all(
        $_link-color: $color-blue4
    );
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 4px 10px;
    font-size: 13px;
    font-weight: $font-weight__medium;
    height: 35px;
    border: 1px solid $color-blue4;
    border-left: 0;
    border-radius: 0 4px 4px 0;
    order: 2;
    width: fit-content;
    white-space: nowrap;

    .login-for-price__link-shape {
        position: absolute;
        top: -1px;
        left: -10px;

        img {
            height: 100%;
        }
    }

    span {
        text-decoration: underline;
        text-transform: capitalize;
    }

    &:hover {
        text-decoration: none;
    }

    &:active,
    &:focus {
        color: $color-black;
    }
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            margin-bottom: $indent__s;
        }
    }

    .stock {
        &.available,
        &.unavailable {
            margin-bottom: $indent__s;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                margin-bottom: $indent__s;
                @include lib-css(color, $text__color__muted);

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        .old-price,
        .normal-price .price-label {
            display: block;
        }
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size($font-size__base);
    }
}

.product-info-summary {
    box-sizing: border-box;

    .overview {
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 10px;
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &::after {
                    content: '*';
                    @include lib-typography(
                        $_font-size: $form-field-label-asterisk__font-size,
                        $_color: $form-field-label-asterisk__color,
                        $_font-family: $form-field-label-asterisk__font-family,
                        $_font-weight: $form-field-label-asterisk__font-weight,
                        $_line-height: $form-field-label-asterisk__line-height,
                        $_font-style: $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: block;
        margin: 0 0 $indent__s;

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .box-tocart {
        margin: $indent__base 0 0;

        .action.primary {
            background: $theme-color-yellow3;
            border: 1px solid $theme-color-yellow3;
            color: #1f385e;

            &:before {
                color: #1f385e;
            }

            &:hover {
                background: darken($theme-color-yellow3, 5%);
            }
        }

        .fieldset {
            display: flex;
            flex-wrap: wrap;
        }

        .field.qty {
            padding-right: 10px;
        }

        .input-text.qty {
            height: auto;
        }

        .qty-select {
            height: 50px;
        }

        .actions {
            flex: 1;
            display: flex;
            align-items: flex-end;
            margin: 0 0 10px;

            .tocart {
                width: 100%;
                height: 50px;
                white-space: nowrap;
            }
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .product-addto-links {
        margin: 0 0 $indent__base;
    }

    .action.tocompare {
        @extend .abs-actions-addto;
        vertical-align: top;
    }
}

.quote.action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    height: 50px;
    background-color: $theme-color-light-blue3;
    border-color: $theme-color-light-blue3;
    color: $theme-color-blue1;

    &:hover {
        background-color: darken($theme-color-light-blue3, 5%);
        border-color: darken($theme-color-light-blue3, 5%);
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @include lib-font-size(14);
            font-weight: $font-weight__bold;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

.product-usps {
    @extend .abs-usps;
    margin: 0 0 $indent__base;
    padding-top: 5px;

    li {
        color: $theme-text-color-lighter;

        &::before {
            color: $theme-color-blue1;
        }
    }
}

.category-seo-text {
    @extend .abs-margin-for-blocks-and-widgets;
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            position: relative;
        }

        .product-item:not(:last-of-type) {
            margin-bottom: $indent__base;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

.attribute-group {
    &__title {
        margin-bottom: 10px;
    }
}

.catalog-product-view {
    .action.primary.contact-us.quote-product {
        margin-right: 20px;
    }

    .page-title-wrapper {
        .page-title {
            margin-bottom: $indent__s;
        }
    }

    .product-info-price {
        .price-box > .price-final_price, .price-box .special-price {
            .price {
                font-weight: $font-weight__semibold;
                color: $theme-color-blue1;
                margin-bottom: 0;
                margin-right: 22px;
            }
        }

        .price-box {
            .normal-price {
                .price {
                    font-weight: $font-weight__semibold;
                }
            }
        }
    }

    .page-sub-title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: $indent__base;
    }

    .table.additional-attributes {
        @include lib-table-resize(
            $_th-padding-top: $table-cell__padding-horizontal,
            $_th-padding-left: 0,
            $_th-padding-right: $table-cell__padding-vertical,
            $_th-padding-bottom: $table-cell__padding-horizontal,
            $_td-padding-top: $table-cell__padding-horizontal,
            $_td-padding-left: 0,
            $_td-padding-right: 0,
            $_td-padding-bottom: $table-cell__padding-horizontal
        );

        tr:not(:last-of-type) {
            border-bottom: 1px solid $border-color__base;
        }

        th,
        td {
            width: 50%;
        }

        th {
            font-weight: $font-weight__regular;
        }
    }

    .action.towishlist {
        @include lib-icon-font-size(
            $_icon-font-size: 20px
        );

        &::before {
            margin: -2px 10px 0 0;
            color: $theme-icon-color-light;
        }

        &:hover {
            &::before {
                color: darken($theme-icon-color-light, 5%);
            }
        }
    }
}


.special-price {
    .price-label {
        display: none;
    }
}

.qty-increments-notice {
    @include lib-message(info);
}

.block-description {
    margin: 0;

    .description {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product-info-columns {
        &__item {
            margin-left: -$layout-indent__width;
            margin-right: -$layout-indent__width;

            .block:not(.block-description):not(.review-add):not(.attribute-groups),
            .product.description {
                margin-bottom: 20px;
                padding-bottom: 20px;
                padding-left: $layout-indent__width;
                padding-right: $layout-indent__width;
                border-bottom: 1px solid $border-color__base;
            }

            .product.description {
                padding-bottom: 0;
            }

            .block.review-add {
                margin-bottom: 0;
            }

            .block.attribute-groups {
                margin-bottom: 20px;
                padding-left: $layout-indent__width;
                padding-right: $layout-indent__width;
            }
        }
    }

    .product-info-detail-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .product-info-summary {
        order: 1;
    }

    .product.media {
        order: 2;
    }

    .product-info-main {
        order: 3;
    }

    .product.info.detailed {
        .additional-attributes {
            * {
                display: block;
            }

            tbody > tr > th {
                padding-bottom: 0;
            }

            tbody > tr > td {
                padding-left: 0;
            }
        }
    }

    .catalog-product-view {
        .column.main {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }

    .category-image {
        display: none;
    }

    .category-description-wrapper {
        flex-direction: column;
    }
}

@include screen($screen__m, $screen__l) {
    .catalog-category-view {
        &.page-layout-2columns-left {
            .column.main {
                @include make-col($total-columns);
            }
        }
    }
}

@include max-screen($screen__l) {
    .catalog-category-view {
        &.page-layout-2columns-left {
            .sidebar-additional {
                display: none;
            }

            .block.filter {
                margin-bottom: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main,
    .product-info-summary {
        padding-left: 15px;
    }

    .product-info-main {
        margin-bottom: 40px;
    }

    .product-info-bottom {
        clear: both;
    }

    .product.media {
        padding-right: 15px;
    }

    .product-info-summary, .product-info-main {
        padding-right: 110px;
    }

    .catalog-product-view {
        .product.attribute.description {
            margin-bottom: 60px;
        }
    }

    .product.info.detailed {
        .additional-attributes {
            table-layout: fixed;

            tbody > tr > th {
                position: relative;
                width: 190px;
                padding-right: 20px;
                font-weight: $font-weight__regular;

                &::after {
                    content: ':';
                    position: absolute;
                    right: 5px;
                }
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }
}

button.quote {
    margin-left: 10px;
    max-height: 50px;
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product.media {
        float: left;
    }

    .product-info-summary,
    .product-info-main {
        float: right;
    }

    .product-info-bottom {
        position: relative;
        padding-top: 60px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: $layout-indent__width / 2;
            left: $layout-indent__width / 2;
            border-top: 1px solid $border-color__base;
        }
    }

    .product-info-main,
    .product.media {
        box-sizing: border-box;
    }

    .product.media {
        margin-bottom: $indent__m;
    }

    .product-info-main,
    .product.media,
    .product-info-summary {
        width: 50%;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__l) {
    .product-info-columns {
        @include make-row();

        &__item {
            @include make-col-ready();
            @include make-col(12);

            &--left {
                padding-right: 44px;
            }

            &--right {
                padding-left: 44px;
            }
        }
    }

    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            .action.tocart {
                @include lib-icon-font(
                    $_icon-font-content: $icon-cart,
                    $_icon-font-size: 18px,
                    $_icon-font-margin: 0 10px 0 0,
                    $_icon-font-vertical-align: top
                );
            }

            .label {
                color: $theme-color-dark-gray1;
            }
        }
    }

    .product-info-main,
    .product-info-summary {
        padding-left: 44px;
    }

    .product.media {
        padding-right: 44px;
    }

    .category-image {
        margin-bottom: $indent__l;
    }
}

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            @include lib-font-size(13);

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: 0;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

.category-description-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.category-description {
    max-height: none;
    position: relative;
    line-height: 1.57;

    &:not(.collapsed) {
        .toggle-description {
            margin-top: 0;

        }
    }

    &.collapsed {
        display: inline-block;
        display: -webkit-box;
        flex: 1;
        max-height: 3em;
        margin-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
}

.toggle-description {
    @include lib-css(margin-bottom, lib-font-size-value($p__margin-bottom));
    display: inline-block;
    white-space: nowrap;
}
