.std {
    @extend .abs-margin-for-blocks-and-widgets;
    
    table {
        margin-bottom: $indent__m;
        
        tr {
            border-bottom: 1px solid $border-color__base;
            
            > td,
            > th {
                &:first-child {
                    padding-left: 0;
                }
                
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

.sidebar {
    .footer-menu__top-link {
        @include lib-heading(h3);
        display: inline-block;
    }
    
    .footer-menu__sub-menu-topall {
        display: none;
    }
    
    .footer-menu__sub-menu-list {
        @include abs-sidebar-list();
        margin-bottom: 30px;
    }
}

.company-info {
    display: flex;
    padding-bottom: 30px;
    
    ul {
        @include lib-list-reset-styles();
        padding-right: 30px;
        
        li {
            margin: 0;
        }
    }
}

.show-mobile {
    display: none;
}

@include max-screen($screen__m) {
    .company-info {
        flex-direction: column;
        
        ul {
            padding-bottom: 15px;
        }
    }
    
    .show-mobile {
        display: block;
    }
}
