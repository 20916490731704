$footer__background-color: $theme-color-light-blue3 !default;

.page-footer {
    @include lib-css(background-color, $footer__background-color);
}

.footer {
    &.content {
        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }
}

.footer-menu {
    &,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;

    img {
        padding-bottom: 20px;
    }
}

.footer-columns {
    margin-bottom: 40px;
}

.footer-copyright {
    font-size: 12px;
}

.footer-payment-providers {
    margin-bottom: 10px;
    text-align: right;
}

.after-footer-columns {
    .block.newsletter {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }
}

.footer-socials {
    text-decoration: none;
}

.company-info-wrapper {
    ul {
        @include lib-list-reset-styles();

        li {
            margin: 0;
        }
    }
}

.top-footer {
    margin-bottom: 40px;
    padding: 25px 0;
    background-color: $brand__primary__color;
    color: $color-white;

    &__left {
        font-size: 17px;
    }

    &__right {
        ul {
            @include lib-list-reset-styles();
            display: flex;
            align-items: center;
        }

        li {
            @include lib-icon-font(
                $_icon-font-content: false,
                $_icon-font-margin: 0 10px 0 0,
                $_icon-font-size: 22px,
                $_icon-font-color: $color-yellow1
            );
            margin: 0;
            font-weight: $font-weight__medium;

            &:nth-child(1) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-delivery
                );

                &::before {
                    font-size: 20px;
                }
            }

            &:nth-child(2) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-lightbulb-regular
                );
            }

            &:nth-child(3) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-euro-sign-solid
                );

                &::before {
                    font-size: 19px;
                }
            }

            &:not(:first-child) {
                margin-left: 25px;
            }
        }
    }
}

@include min-screen($screen__m) {
    .footer-columns {
        display: flex;
        justify-content: center;
        align-items: baseline;
        margin-bottom: 60px;

        .footer-menu-container {
            display: flex;
            justify-content: space-between;
            width: 75%;
        }

        .footer-menu {
            display: flex;
            width: 100%;

            &__item {
                flex: 1;
            }

            &__top-link,
            &__sub-menu-link {
                @include lib-link-all(
                    $_link-color: $text__color
                );
            }

            &__sub-menu-topall {
                display: none;
            }

            &__top-link {
                @include lib-heading(h4);
                @include lib-link-all(
                    $_link-color: $heading__color__base
                );
                display: inline-block;
            }

            &__sub-menu-item {
                margin-bottom: 8px;
                line-height: 1.2;
            }
        }
    }

    .footer-bottom {
        margin-bottom: 60px;
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .top-footer {
        margin-bottom: 60px;

        &__inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }
    }
}

//
//  Mobile
//  _____________________________________________


@include max-screen($screen__m) {
    .footer.content .footer-menu {
        margin-right: -$layout-indent__width;
        margin-left: -$layout-indent__width;

        &__item {
            position: relative;
            margin: 0;
            padding: 15px 35px 15px $layout-indent__width;
            border-bottom: 1px solid $border-color__base;

            &:first-child {
                border-top: 1px solid $border-color__base;
            }
        }

        &__top-link {
            @include lib-link-all(
                $_link-color: $text__color
            );
        }

        &__sub-menu-topall {
            display: none;
        }
    }

    .footer-columns {
        .block.newsletter {
            form.subscribe {
                width: 75%;
            }

        }

        .social-media {
            margin-top: 20px;
        }
    }

    ul > li {
        margin: 0;
    }

    .top-footer {
        display: none;
    }

    .footer-payment-providers {
        text-align: center;
    }
}
